import { IsNotNull } from "../commons/helper";

const jwt = require("jsonwebtoken");
const encrypt = require("../encrypt/encrypt");
const authControl = require("../auth/auth");
const { cryptr_decrypt, cryptr_encrypt } = require("../encrypt/encrypt");
const { compress, decompress } = require('shrink-string')
var decrypt_rs, encrypt_jwt_token, user
const checkAuth = () => {
  var [p1, p2, p3] = authControl.loadAuth()
  decrypt_rs = p1
  encrypt_jwt_token = p2
  user = p3
}

const userAll = async (pageNum, pageSize) => {
  var result = { data: null, tp: 1 };
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-all/" + pageNum + "&" + pageSize
    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.message == "get data Success") {
          const decrypted = encrypt.cryptr_decrypt().decrypt(data.token);
          const rs_d = jwt.decode(decrypted);
          result.data = rs_d.data;
          result.tp = rs_d.total_pages;
        } else if (data.message == "data empty") {
          result = { data: null, tp: 1 };
        } else if (data.error == "jwt expired") {
          result = { data: 'jwt expired', tp: 1 };
        }
      });
    return result;
  } catch (e) {
    console.error(e);
  }
}

const createUser = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-create"
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3,
        p4: b.p4,
        p5: b.p5,
        p6: b.p6,
        p7: b.p7,
        created_by: user
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message
      });
    return result;
  } catch (e) {
    console.error(e);
  }
}

const deleteUser = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-delete"
    await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message;
      });
    return result;
  } catch (e) {
    console.error(e);
  }
}

const search = async (b) => {
  var result = { data: null, tp: 1 };
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-search"
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message == "get data Success") {
          const decrypted = encrypt.cryptr_decrypt().decrypt(data.token);
          const rs_d = jwt.decode(decrypted);
          result.data = rs_d.data;
          result.tp = rs_d.total_pages;
        } else if (data.message == "data empty") {
          result = { data: null, tp: 1 };
        } else if (data.error == "jwt expired") {
          result = { data: 'jwt expired', tp: 1 };
        }
      });
    return result;
  } catch (e) {
    console.error(e);
  }
}

const updateUser = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-update"
    await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3,
        p4: b.p4,
        p5: b.p5,
        p6: b.p6,
        update_by: user
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message
      });
    return result;
  } catch (e) {
    console.error(e);
  }
}

const resetPwd = async (b) => {
  let result = { code: '', status: false };
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "user-rst-pwd"
    await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        data: encrypt.cryptr_encrypt().encrypt(JSON.stringify({
          p1: b.p1 !== null ? b.p1 : user,
          p2: b.p2,
          update_by: user
        }))
      }),
    })
      .then(response => response.json())
      .then(x => { result = x; });
  } catch (e) {
    return { code: e.code, status: false }
  } finally {
    return result;
  }
}

const getUserBarOptions = async () => {
  let result = null;
  try {
    checkAuth()
    let url = process.env.REACT_APP_API_URL + "user-bar-opt"
    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }
    })
      .then(response => response.json())
      .then(async x => {
        if (IsNotNull(x.token)) {
          let decompressed = await decompress(x.token)
          const decrypted = cryptr_decrypt().decrypt(decompressed);
          const rs_d = jwt.decode(decrypted);
          result = rs_d.data;
        }
      });
  } catch (e) {
    result = null
  } finally {
    return result;
  }
}

export {
  userAll,
  createUser,
  deleteUser,
  search,
  updateUser,
  resetPwd,
  getUserBarOptions
}