import { formatValue } from 'react-currency-input-field';
import imageCompression from 'browser-image-compression';

/**
 * Check is value is null, undefined or empty string
  @param {String} s Tester value.
  @returns {boolean} true/false
*/
const IsNotNull = (s) => {
  return s ? true : false
}

/**
 * Check is pattern is matching
  @param {String} v Tester value.
  @param {String} regexp Tester value.
  @returns {boolean} true/false
*/
const patternCheck = (v, regexp) => {
  return regexp.test(v);
}

const str2ab = (str) => {
  let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  let bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

/**
 * Get Formatted Price
  @param {String} price value.
  @param {String} symbol symbol.
  @param {String} group grouping symbol.
  @param {String} decimal decimal symbol.
  @returns {String} Formatted String Price
*/
const getFormattedPrice = (price, symbol, group, decimal) => {
  let rs = formatValue({
    value: price.toString(),
    groupSeparator: group,
    decimalSeparator: decimal,
    prefix: symbol,
  });
  return rs;
}

/**
 * Get Formatted Number
  @param {String} num value.
  @param {String} group grouping symbol.
  @param {String} decimal decimal symbol.
  @returns {String} Formatted String Number
*/
const getFormattedNumber = (num, group, decimal) => {
  let rs = formatValue({
    value: num.toString(),
    groupSeparator: group,
    decimalSeparator: decimal
  });
  return rs;
}

/**
 * Mapping options 
  @param {root} troot this.
  @param {Array} data options array from db.
  @param {String} data placeholder.

  @param {State} st_opt state options.
  @param {String} f1 db col id.
  @param {String} f2 db col name.
  @param {boolean} cmb combine t/f.
  @param {String} c1 db col.
  @param {String} c2 db col.
  @param {String} dlm delimeter.

  @returns {String} Formatted String Number
*/
const MappingOptions = async (troot, data, ph, st_opt, f1, f2, cmb, c1, c2, dlm) => {
  if (IsNotNull(data)) {
    let g = [];
    let delimeter = IsNotNull(dlm)? dlm : '-'
    data.map(x => { g.push({ label: IsNotNull(cmb) ? cmb ? `${x[c1]} ${delimeter} ${x[c2]}` : x[f2] : x[f2], value: x[f1], o: x }) })
    const options = [{ label: ph, options: g }]
    await troot.setState({ [st_opt]: options })
  }
}

const MappingWNullOptions = async (troot, data, ph, st_opt, f1, f2, cmb, c1, c2) => {
  if (IsNotNull(data)) {
    let g = [{ label: 'None', value: null, o: null }];
    await data.map(x => { g.push({ label: IsNotNull(cmb) ? cmb ? `${x[c1]} - ${x[c2]}` : x[f2] : x[f2], value: x[f1], o: x }) })
    const options = [{ label: ph, options: g }]
    await troot.setState({ [st_opt]: options })
  }
}

/*
    Untuk cek sebelum submit
    troot: this
    rs: result var
    st1: state
    ss: state style
    erm: error message
    type: tipe input text or select (v/s)
  */
const checkValue = async (troot, rs, st1, ss, erm, type, tnotif) => {
  if (!IsNotNull(st1)) {
    rs = false;
    if (type === "v") {
      await troot.setState({
        [ss]: "form-control-err"
      })
    } else if (type === "s") {
      await troot.setState({
        [ss]: "form-select-err"
      })
    }
    IsNotNull(tnotif) ? tnotif(IsNotNull(erm) ? erm : 'Error', { variant: "error", autoHideDuration: 1200 }) : null
  }
  if (troot.state[ss].includes("err")) {
    rs = false;
  }
  return rs
}

const changeID = (arr) => {
  arr.map((x, i) => {
    x.id = i + 1
  })
}

/*
  troot: this
  find_value: find value
  st_opt: state options
  st: state name to set
*/
const FindSetOption = async (troot, find_value, st_opt, st) => {
  let s = troot.state[st];
  let rs = IsNotNull(find_value) ? await st_opt.options.find(option => option.value === find_value) : null
  IsNotNull(rs) ? s = { label: rs.label, value: rs.value, o: rs.o } : s = { label: "", value: null, o: null }
  await troot.setState({ [st]: s })
}

const FindSetOptionWKey = async (troot, find_value, st_opt, st, key) => {
  let s = troot.state[st];
  let rs = IsNotNull(find_value) ? await st_opt.options.find(option => option[key] === find_value) : null
  IsNotNull(rs) ? s = { label: rs.label, value: rs.value, o: rs.o } : s = { label: "", value: null, o: null }
  await troot.setState({ [st]: s })
}

const FindOption = (find_value, st_opt) => {
  return IsNotNull(find_value) && IsNotNull(st_opt) ? st_opt.options.find(option => option.value === find_value) : ''
}

/*
  value: this
  has_dec: has decimal
  dec: max decimal
*/
const toFloat = (value, has_dec, dec) => {
  let tmp_val = IsNotNull(value) ? value : 0
  let rs = 0
  rs = parseFloat(has_dec ? parseFloat(tmp_val.toString().replace(/,/g, '.')).toFixed(dec) : tmp_val.toString().replace(/,/g, '.'))
  return rs
}

/*
for check isnotnull or return default
  value: check value
  def: default value
*/
const INNRD = (value, def) => {
  return IsNotNull(value) ? value : def
}

/*
for check isnotnull for select option value return default
  opt: check option
  def: default value
*/
const INNSD = (opt, def) => {
  return IsNotNull(opt.value) ? opt : def
}

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


/**
  @param {any} value value to check.
  @param {any} v1 return this value.
  @param {any} v2 return this value.
  @param {any} c1 value 1 for compare v1.
  @param {any} c2 value 2 for compare v2.
  @param {any} def return default value.
*/
const condCheckRD = (value, def, c, v) => {
  let rs = def
  IsNotNull(value) ? IsNotNull(c) ? c.map((x, i) => { rs = x === value ? v[i] : rs }) : null : null
  return rs
}
// const condCheckRD = (value, def, c1, c2, v1, v2) => {
//   let rs = null
//   if (IsNotNull(value)) {
//     rs = value === c1 ? v1 : value === c2 ? v2 : def
//   }
//   return rs
// }

const checkTotalQty = (troot, value, rate, id) => {
  let rs = toFloat(value) * rate
  let qty_c = 0
  let total = 0, result = { status: true, exceed: 0 }
  let reqStockConv = toFloat(troot.state.obj_select.qty_conversion)
  let tmpAvQty = toFloat(troot.state[`v_available_qty${id}`])
  let rs_qty_conv = 0, rs_qty = 0
  if (patternCheck(value, /^[0-9.]*$/)) {
    troot.state.data_table.map(x => {
      let rate_x = toFloat(troot.state[`v_qty_unit${x.id}`].rate)
      let sum = toFloat(troot.state[`v_qty${x.id}`]) * rate_x
      total = total + sum
    })
    if (total > reqStockConv) {
      let exceed = total - reqStockConv
      let rs_used = rs - exceed
      if (rs_used >= tmpAvQty) {
        rs_qty = Math.floor(tmpAvQty / rate)
        rs_qty_conv = rs_qty * rate
      } else {
        rs_qty = Math.floor(rs_used / rate)
        rs_qty_conv = rs_qty * rate
      }
      troot.setState({ [`s_i_qty${id}`]: "form-control-err" })
    } else {
      if (rs > tmpAvQty) {
        rs_qty = Math.floor(tmpAvQty / rate)
        rs_qty_conv = rs_qty * rate
      } else {
        rs_qty = Math.floor(rs / rate)
        rs_qty_conv = rs_qty * rate
      }
    }
    troot.setState({
      [`v_qty_conversion${id}`]: rs_qty_conv,
      [`v_qty${id}`]: rs_qty
    })
  }
}

/**
  @param {any} x file.
  @param {any} tnotif notif component/enqueueSnackbar.
*/
const ImageCompress = async (x, tnotif) => {
  try {
    let file = x.file
    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (file.type.match('image.*')) {
      file = await imageCompression(file, options);
    }
    return new Blob([file], {
      type: file.type,
    })
  }
  catch (err) {
    IsNotNull(tnotif) ? tnotif(IsNotNull(erm) ? erm : 'Compress Image Failed!!', { variant: "error", autoHideDuration: 1200 }) : null
  }
}

const BlobsEqualCheck = async (blob1, blob2) => {
  if (IsNotNull(blob1) && IsNotNull(blob2)) {
    return !Buffer.from(await blob1.arrayBuffer()).compare(
      Buffer.from(await blob2.arrayBuffer())
    );
  } else{
    throw new Error('blob1 or blob2 is null!!')
  }
};

export {
  IsNotNull,
  patternCheck,
  checkValue,
  getFormattedPrice,
  getFormattedNumber,
  MappingOptions,
  changeID,
  FindSetOption,
  toFloat,
  INNRD,
  str2ab,
  INNSD,
  toTitleCase,
  FindOption,
  MappingWNullOptions,
  condCheckRD,
  checkTotalQty,
  FindSetOptionWKey,
  ImageCompress,
  BlobsEqualCheck
}